import React, { useState } from 'react';
import Logo from '../logo.svg'
import config from '../config';
import { BeatLoader } from 'react-spinners';

const SampleGeneration = () => {
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);

    const handleClick = () => {
        setIsLoading(true);
        fetch(`https://api.snapmydesign.com/api/v1/texttoimage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "user_id": "aZh2O3ObAWZCwFUCEce8j8qEZ9b2",
                    "width": "1024",
                    "height": "1024",
                    "prompt": inputValue,
                    "refine": "expert_ensemble_refiner",
                    "scheduler": "K_EULER",
                    "lora_scale": "0.6",
                    "guidance_scale": "6",
                    "prompt_id": "I59uHpaVSQSh5C4SSN7F",
                    "apply_watermark": "false",
                    "high_noise_frac": "0.8",
                    "negative_prompt": config.negativePrompt,
                    "prompt_strength": "0.8",
                    "num_inference_steps": "25",
                    "style_name": "Illustration",
                    "creditCost": "1",
                    "number_of_images": "2",
                    "speed_type": "High Speed"
                }
            ) // replace with your data
        })
            .then(response => response.json())
            .then(data => {
                setImageUrls(data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false);
            });

    };

    return (
        <section id="sample-generation" className="py-20 bg-white dark:bg-gray-900">
            <div className="mb-12 text-center">
                <div className="flex justify-center items-center"> {/* Add this line */}
                    <label className="text-4xl font-bold text-gray-700 dark:text-white md:text-4xl">
                        Try Live Demo Here! :
                    </label>
                    <input className="text-xl text-gray-700 dark:text-white md:text-xl border-2 border-gray-300 p-2 ml-3"
                        type="text"
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        style={{ width: '600px' }}
                    />
                    <button
                        onClick={handleClick}
                        className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max ml-3"
                    >
                        <span className="relative text-base font-semibold text-white">Generate</span>
                    </button>
                </div>
                <div className='mt-10 flex justify-center items-center'>
                    {isLoading ? (
                        <BeatLoader color="#123abc" loading={isLoading} size={150} />
                    ) : imageUrls.length > 0 ? (
                        imageUrls.map((url, index) => (
                            <div className='m-8' key={index} style={{ width: '400px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={url} alt={`Generated ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        ))
                    ) : null}
                </div>
            </div>
        </section >

    );
};

export default SampleGeneration;